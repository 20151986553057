
  import { defineComponent, reactive, ref, watch, getCurrentInstance } from "vue"
  import FormStepper from "@/components/shared/FormStepper.vue"
  import { Datetime } from 'vue-datetime'
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import countries from "i18n-iso-countries"

  export default defineComponent({
    components: {
      FormStepper,
      SchemaForm
    },
    props: {
      agency: {
        type: Object
      }
    },
    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const formKey = ref(Math.random())

      countries.registerLocale(require("i18n-iso-countries/langs/de.json"))
      const countriesDe = Object.values(countries.getNames('de'))
      const listQuery = {'filter[sys_id]': 'available_cg_nationalities',include: 'sublists'}
      root.$store.dispatch('lists/load', listQuery)

      const componentPicker = (type: string) => {
        return {
          input: 'BFormInput',
          radio: 'BFormRadioGroup',
          mc: 'BFormCheckboxGroup',
          date: Datetime,
        }[type]
      }

      const formSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/agency-model.json",
        type: "object",
        properties: {
          name: { type: "string", title: "Firmenname" },
          companyType: { type: "string", title: "Geschäftsform (e.g. GmbH)" },
          company_address: {
            type: "object",
            title: "Firmen Adresse",
            properties: {
              id: { type: "string", title: "id"},
              type: { type: "string", title: "type"},
              attributes: {
                type: "object",
                title: "",
                properties: {
                  companyName: { type: "string", title: "Firmenname" },
                  gender: { type: "string", title: "Geschlecht" },
                  title: { type: "string", title: "Titel" },
                  firstName: { type: "string", title: "Vorname" },
                  lastName: { type: "string", title: "Nachname" },
                  street: { type: "string", title: "Strasse" },
                  houseNumber: { type: "string", title: "Hausnummer" },
                  additional: { type: "string", title: "Adresszusatz" },
                  zipCode: { type: "string", title: "PLZ" },
                  city: { type: "string", title: "Ort" },
                  country: { type: "string", title: "Land" },
                  phones: {
                    title: "Phones",
                    type: "array",
                    items: {
                      type: "object",
                      properties: {
                        number: { type: "string", title: "Telefonnummer" },
                        name: { type: "string", title: "Bezeichnung" },
                        default: { type: "boolean", title: "Default Nummer" }
                      }
                    }
                  },
                  emails: {
                    title: "Emails",
                    type: "array",
                    items: {
                      type: "object",
                      properties: {
                        email: { type: "string", title: "E-Mail-Adresse", format: "email" },
                        name: { type: "string", title: "Bezeichnung" },
                        default: { type: "boolean", title: "Default E-Mail-Adresse" }
                      }
                    }
                  },
                  tax: {
                    type: "object",
                    properties: {
                      taxNr: { type: "string", title: "Steuernummer" },
                      taxId: { type: "string", title: "Steuer ID" },
                      ustId: { type: "string", title: "USt.-ID" }
                    }
                  }
                }
              }
            }
          },
          founded: { type: "integer", title: "Gründungsjahr (Zahl)" },
          noGerFamilies: { type: "integer", title: "Betreute dt. Familien (Zahl)" },
          noOfficeEmployees: { type: "integer", title: "Anzahl Büromitarbeiter (Zahl)" },
          noGerOfficeEmployees: { type: "integer", title: "davon deutschsprachig (Zahl)" },
          homepage: { type: "string", title: "Webadresse" },
          jurisdiction: { type: "string", title: "Gerichtsstand" },
          offeredCourses: {
            type: "array",
            title: "Angebotene Weiterbildungskurse",
            description: "Welche Qualifizierungs und Weiterbildungskurse sind vorhanden",
            items: { type: "string" }
          },
          gerSkillTestMethod: {
            type: "string",
            title: "Prüfungsverfahren Sprachkenntnisse",
            description: "Wie werden die Sprachkenntnisse geprüft"
          },
          dubHh: { type: "number", title: "Zuschlag 2 Pers. Haushalt von (€)" },
          dubHhUpper: { type: "number", title: "Zuschlag 2 Pers. Haushalt bis (€)" },
          dubCr: { type: "number", title: "Zuschlag 2 Pflegebedürftige von (€)" },
          dubCrUpper: { type: "number", title: "Zuschlag 2 Pflegebedürftige bis (€)" },
          nightCare: { type: "number", title: "Zuschlag Nachtdienst von (€)" },
          nightCareUpper: { type: "number", title: "Zuschlag Nachtdienst bis (€)" },
          nightCareComment: { type: "string", title: "Kommentar Nachtdienst" },
          driversLicense: { type: "number", title: "Zuschlag Führerschein (€)" },
          driversLicenseComment: { type: "string", title: "Kommentar Führerschein" },
          holidays: {
            type: "array",
            title: "Feiertage",
            items: {
              type: "object",
              properties: {
                name: { type: "string", title: "Name", examples: ["Weihnachten"] },
                date: { type: "string", title: "Datum", examples: ["24.12"] },
                surchargeFix: { type: "number", title: "Zuschlag (fest)", examples: [60.50] },
                surchargeFactor: { type: "number", title: "Zuschlag (Faktor)", examples: [1.7] },
              }
            }
          },
          travelOptions: {
            type: "array",
            title: "Reisemöglichkeiten",
            items: {
              type: "object",
              properties: {
                name: { type: "string", title: "Name", examples: ["Minibus"] },
                cost: { type: "number", title: "Kosten (€)", examples: [120] },
                comment: { type: "string", title: "Kommentar" },
              }
            }
          },
          contractDuration: { type: "string", title: "Vertragsdauer (z.B.: 12 Monate, unbefristed)" },
          hoursPerWeek: { type: "number", title: "Wöchentliche Arbeitszeit (Stunden) (z.B.: 35.5)", examples: ["30.5"] },
          noticePeriodCancellation: { type: "integer", title: "Kündigungsfrist (Zahl: Anz. Tage)" },
          noticePeriodCancellationComment: { type: "string", title: "Kommentar Kündigungsfrist (Bsp. 40 Stunden)" },
          noticePeriodDeath: { type: "integer", title: "Kündigungsfrist Todesfall (Zahl: Anz. Tage)" },
          noticePeriodDeathComment: { type: "string", title: "Kommentar Kündigungsfrist Todesfall" },
          contractPause: {
            type: "string",
            title: "Regelung für Vertragspausen",
            description: "Regelung für Vertragspausen"
          },
          revocationDays: { type: "integer", title: "Zahlbare Tage bei Widerruf  (Zahl)" },
          revocationComment: { type: "string", title: "Regelung bei Widerruf (Vertrag startet nicht)" },
          autofill: { type: "boolean", title: "Agentur füllt das Vertragsdokument aus" },
          dk1: { type: "integer", title: "DK1" },
          dk2: { type: "integer", title: "DK2" },
          dk3: { type: "integer", title: "DK3" },
          dk4: { type: "integer", title: "DK4" },
          dk5: { type: "integer", title: "DK5" },
          dk6: { type: "integer", title: "DK6" },
          nationalities: {
            type: "array",
            title: "Vermittelbare Nationalitäten",
            items: { type: "string" }
          },
          gerMinimumWage: { type: "boolean", title: "deutscher Mindestlohn" },
          liabilityInsurance: { type: "boolean", title: "Haftpflichtversicherung" },
          liabilityInsuranceComment: {
            type: "string",
            title: "Kommentar Haftpflichtversicherung",
            description: "Konditionen e.g. Versicherungslimit",
          },
          accidentInsurance: { type: "boolean", title: "Unfallversicherung" },
          accidentInsuranceComment: {
            type: "string",
            title: "Kommentar Unfallversicherung",
            description: "Konditionen e.g. Versicherungslimit",
          },
          a1Insurance: { type: "boolean", title: "Krankenversichert durch A1" },
          healthInsurance: { type: "boolean", title: "Extra Krankenversichert" },
          healthInsuranceName: { type: "string", title: "Name der Extra-Krankenversicherung" },
          additionalInsurances: { type: "string", title: "weitere Versicherungen" },
          monthlyBillDays: { type: "float", title: "Rechnungstage pro Monat (Zahl)" },
          monthlyBillDaysComment: { type: "string", title: "Kommentar Rechnungstage" },
        },
        required: ["name", "dubHh", "dubCr", "nightCare", "driversLicense", "holidays", "travelOptions", "noticePeriodCancellation", "noticePeriodDeath", "revocationDays", "nationalities", "dk1", "dk2", "dk3", "dk4", "dk5", "dk6", "gerMinimumWage", "liabilityInsurance", "accidentInsurance", "accidentInsuranceInfo"]
      }

      const uiSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/agency-view.json",
        $vocabulary: {
          "https://json-schema.org/draft/2019-09/vocab/core": true,
          "https://crm.pflegehilfe-senioren.de/ui-vocab": true
        },
        "ui:type": "form",
        "ui:widget": "b-row",
        type: "object",
        properties: {
          company: {
            "ui:type": "group",
            "ui:label": "",
            type: "object",
            "ui:order": ["companyType", "name"],
            properties: {
              name: {
                $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/name",
                "ui:label": "Firmenname (Kurzform)",
                "ui:classNames": ["col-md-6"],
              },
              companyType: {
                $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/companyType",
                "ui:classNames": ["col-md-6"]
              },
              company_address: {
                "ui:type": "group",
                "ui:label": "",
                $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address",
                type: "object",
                properties: {
                  attributes: {
                    "ui:type": "group",
                    "ui:label": "",
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes",
                    type: "object",
                    properties: {
                      ceo: {
                        type: "object",
                        "ui:label": "Geschäftsführung",
                        "ui:type": "group",
                        properties: {
                          gender: {
                            "ui:type": "collection",
                            "ui:widget": "v-select",
                            "ui:options": {
                              options: [
                                {value: "m", label: "Mann"},
                                {value: "f", label: "Frau"},
                                {value: "d", label: "Divers"},
                              ],
                              reduce: (i) => i.value
                            },
                            "ui:classNames": ["col-sm-6 col-lg-2"],
                            "ui:label": "Geschlecht",
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/gender",
                            items: {
                              type: "string",
                              $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/gender/items"
                            }
                          },
                          title: {
                            "ui:type": "collection",
                            "ui:widget": "v-select",
                            "ui:options": { options: ['Prof.', 'Dr.'] },
                            "ui:classNames": ["col-sm-6 col-lg-2"],
                            "ui:label": "Titel",
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/title",
                            items: {
                              type: "string",
                              $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/title/items"
                            }
                          },
                          firstName: {
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/firstName",
                            "ui:classNames": ["col-sm-6 col-lg-4"]
                          },
                          lastName: {
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/lastName",
                            "ui:classNames": ["col-sm-6 col-lg-4"]
                          },
                        }
                      },
                      addressBlock: {
                        type: "object",
                        "ui:label": "Firmenadresse",
                        "ui:type": "group",
                        "ui:classNames": ["mt-5"],
                        properties: {
                          companyName: {
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/companyName",
                            "ui:label": "Firmenname (Offiziell)",
                          },
                          street: {
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/street",
                            "ui:classNames": ["col-md-8 col-lg-5"]
                          },
                          houseNumber: {
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/houseNumber",
                            "ui:classNames": ["col-md-4 col-lg-2"]
                          },
                          additional: { $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/additional","ui:classNames": ["col-md-12 col-lg-5"] },
                          zipCode: { $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/zipCode", "ui:classNames": ["col-sm-4 col-lg-2"] },
                          city: { $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/city", "ui:classNames": ["col-sm-8 col-lg-5"]  },
                          country: { $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/country", "ui:classNames": ["col-lg-5"]  },
                          phones: {
                            "ui:type": "collection",
                            "ui:label": "Telefonnummer",
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/phones",
                            items: {
                              type: "object",
                              "ui:type": "group",
                              properties: {
                                name: {
                                  $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/phones/items/properties/name",
                                  "ui:classNames": ["col-xl-5"]
                                },
                                number: {
                                  $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/phones/items/properties/number",
                                  "ui:classNames": ["col-sm-10 col-xl-5"]
                                },
                                default: {
                                  $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/phones/items/properties/default",
                                  "ui:label": "Standard",
                                  "ui:type": "checkbox",
                                  "ui:classNames": ["col-sm-2"],
                                  "ui:options": {
                                    disabled: false,
                                    setter: (setElement, scope, value) => {
                                      const phonesSize = form.company_address.attributes.phones.length
                                      const splitScope = scope.split('/')
                                      const currentIndex = splitScope[splitScope.length - 2]
                                      for (let i = 0; i < phonesSize; i++) {
                                        if (i !== currentIndex && value) {
                                          setElement(`/company_address/attributes/phones/${i}/default`, false)
                                        }
                                      }
                                      setElement(scope, value)
                                    }
                                  }
                                },
                              }
                            }
                          },
                          emails: {
                            "ui:type": "collection",
                            "ui:label": "Emails",
                            $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/emails",
                            items: {
                              type: "object",
                              "ui:type": "group",
                              properties: {
                                name: {
                                  $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/emails/items/properties/name",
                                  "ui:classNames": ["col-xl-5"]
                                },
                                email: {
                                  $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/emails/items/properties/email",
                                  "ui:classNames": ["col-sm-10 col-xl-5"]
                                },
                                default: {
                                  $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/emails/items/properties/default",
                                  "ui:label": "Standard",
                                  "ui:type": "checkbox",
                                  "ui:classNames": ["col-sm-2"],
                                  "ui:options": {
                                    disabled: false,
                                    setter: (setElement, scope, value) => {
                                      const emailsSize = form.company_address.attributes.emails.length
                                      const splitScope = scope.split('/')
                                      const currentIndex = splitScope[splitScope.length - 2]
                                      for (let i = 0; i < emailsSize; i++) {
                                        if (i !== currentIndex && value) {
                                          setElement(`/company_address/attributes/emails/${i}/default`, false)
                                        }
                                      }
                                      setElement(scope, value)
                                    }
                                  }
                                },
                              }
                            }
                          },
                          tax: {
                            type: "object",
                            "ui:label": "",
                            "ui:type": "group",
                            properties: {
                              taxId: {
                                $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/tax/properties/taxId",
                                "ui:classNames": ["col-xl-4"]
                              },
                              taxNr: {
                                $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/tax/properties/taxNr",
                                "ui:classNames": ["col-xl-4"]
                              },
                              ustId: {
                                $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/company_address/properties/attributes/properties/tax/properties/ustId",
                                "ui:classNames": ["col-xl-4"]
                              },
                            }
                          }
                        }
                      }
                    }
                  },
                }
              },
              attributesBlock: {
                type: "object",
                "ui:label": "Angaben zum Unternehmen",
                "ui:type": "group",
                "ui:classNames": ["mt-5"],
                properties: {
                  founded: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/founded",
                    "ui:classNames": ["col-6 col-lg-3"]
                  },
                  noGerFamilies: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/noGerFamilies",
                    "ui:classNames": ["col-6 col-lg-3"]
                  },
                  noOfficeEmployees: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/noOfficeEmployees",
                    "ui:classNames": ["col-6 col-lg-3"]
                  },
                  noGerOfficeEmployees: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/noGerOfficeEmployees",
                    "ui:classNames": ["col-6 col-lg-3"]
                  },
                  homepage: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/homepage",
                    "ui:classNames": ["col-12 col-md-6"]
                  },
                  jurisdiction: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/jurisdiction",
                    "ui:classNames": ["col-12 col-md-6"]
                  },
                  nationalities: {
                    "ui:type": "collection",
                    "ui:widget": "v-select",
                    "ui:options": {
                      multiple: true,
                      options: countriesDe
                    },
                    "ui:label": "Vermittelbare Nationalitäten",
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/nationalities",
                    items: {
                      type: "string",
                      $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/nationalities/items"
                    }
                  },
                  gerSkillTestMethod: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/gerSkillTestMethod",
                    "ui:widget": "b-form-textarea",
                    type: "string"
                  },
                  dubHh: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dubHh",
                    "ui:label": "Kostenaufschlag 2 Pers. von (€)",
                    "ui:classNames": ["col-sm-6 col-lg-3"]
                  },
                  dubHhUpper: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dubHhUpper",
                    "ui:label": "Kostenaufschlag 2 Pers. bis (€)",
                    "ui:classNames": ["col-sm-6 col-lg-3"]
                  },
                  dubCr: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dubCr",
                    "ui:label": "Kostenaufschlag 2 Pfl. Empf. von (€)",
                    "ui:classNames": ["col-sm-6 col-lg-3"]
                  },
                  dubCrUpper: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dubCrUpper",
                    "ui:label": "Kostenaufschlag 2 Pfl. Empf. bis (€)",
                    "ui:classNames": ["col-sm-6 col-lg-3"]
                  },
                  nightCare: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/nightCare",
                    "ui:label": "Kostenaufschlag Nachtdienst von (€)",
                    "ui:classNames": ["col-sm-6 col-lg-3"],
                  },
                  nightCareUpper: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/nightCareUpper",
                    "ui:label": "Kostenaufschlag Nachtdienst bis (€)",
                    "ui:classNames": ["col-sm-6 col-lg-3"],
                  },
                  nightCareComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/nightCareComment",
                    "ui:label": "Kommentar zum Nachtdienst",
                    "ui:classNames": ["col-md-6 col-lg-3"],
                  },
                  driversLicense: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/driversLicense",
                    "ui:label": "Kostenaufschlag Führerschein (€)",
                    "ui:classNames": ["col-sm-6 col-lg-3"],
                  },
                  driversLicenseComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/driversLicenseComment",
                    "ui:label": "Kommentar Führerschein",
                    "ui:classNames": ["col-md-6 col-lg-3"],
                  },
                  holidayGroup: {
                    type: "object",
                    "ui:label": "Feiertage",
                    "ui:type": "group",
                    "ui:classNames": ["col-12 mt-5"],
                    properties: {
                      holidays: {
                        "ui:type": "collection",
                        "ui:label": "Feiertage",
                        $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/holidays",
                        items: {
                          type: "object",
                          "ui:type": "group",
                          properties: {
                            name: {
                              $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/holidays/items/properties/name",
                              "ui:classNames": ["col-sm-6 col-xl-3"]
                            },
                            date: {
                              $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/holidays/items/properties/date",
                              "ui:widget": "datetime",
                              "ui:options": { type: "date" },
                              "ui:classNames": ["col-sm-6 col-xl-3"]
                            },
                            surchargeFactor: {
                              $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/holidays/items/properties/surchargeFactor",
                              "ui:label": "Kostenaufschlag (Faktor)",
                              "ui:classNames": ["col-sm-6 col-xl-3"]
                            },
                            surchargeFix: {
                              $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/holidays/items/properties/surchargeFix",
                              "ui:label": "Kostenaufschlag (Fest)",
                              "ui:classNames": ["col-sm-6 col-xl-3"]
                            }
                          }
                        }
                      },
                    }
                  },
                  travelOptionsGroup: {
                    type: "object",
                    "ui:label": "Reisekosten beziehen sich auf An- und Abreise",
                    "ui:type": "group",
                    "ui:classNames": ["col-12 mt-5"],
                    properties: {
                      travelOptions: {
                        "ui:type": "collection",
                        "ui:label": "Reisemöglichkeiten",
                        $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/travelOptions",
                        items: {
                          "ui:type": "group",
                          type: "object",
                          properties: {
                            name: { $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/travelOptions/items/properties/name", "ui:classNames": ["col-md-4"] },
                            cost: { $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/travelOptions/items/properties/cost", "ui:classNames": ["col-md-4"] },
                            comment: { $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/travelOptions/items/properties/comment", "ui:classNames": ["col-md-4"] }
                          }
                        }
                      },
                    }
                  },
                  contract_duration: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/contractDuration",
                    "ui:classNames": ["col-sm-6 col-md-3"]
                  },
                  hours_per_week: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/hoursPerWeek",
                    "ui:classNames": ["col-sm-6 col-md-3"],
                  },
                  noticePeriodCancellation: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/noticePeriodCancellation",
                    "ui:classNames": ["col-sm-6 col-md-3"]
                  },
                  noticePeriodDeath: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/noticePeriodDeath",
                    "ui:classNames": ["col-sm-6 col-md-3"]
                  },
                  noticePeriodCancellationComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/noticePeriodCancellationComment",
                    "ui:widget": "b-form-textarea",
                    type: "string",
                    "ui:classNames": ["col-6"],
                  },
                  noticePeriodDeathComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/noticePeriodDeathComment",
                      "ui:widget": "b-form-textarea",
                      type: "string",
                      "ui:classNames": ["col-6"],
                    },
                  contractPause: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/contractPause",
                    "ui:classNames": ["col-6"],
                  },
                  revocationDays: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/revocationDays",
                    "ui:classNames": ["col-6"]
                  },
                  revocationComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/revocationComment",
                    "ui:widget": "b-form-textarea",
                    type: "string"
                  },
                  dk1: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dk1",
                    "ui:classNames": ["col-2"]
                  },
                  dk2: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dk2",
                    "ui:classNames": ["col-2"]
                  },
                  dk3: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dk3",
                    "ui:classNames": ["col-2"]
                  },
                  dk4: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dk4",
                    "ui:classNames": ["col-2"]
                  },
                  dk5: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dk5",
                    "ui:classNames": ["col-2"]
                  },
                  dk6: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/dk6",
                    "ui:classNames": ["col-2"]
                  },
                  autofill: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/autofill",
                    "ui:type": "checkbox",
                    "ui:classNames": ["col-4 col-lg-2"]
                  },
                  gerMinimumWage: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/gerMinimumWage",
                    "ui:type": "checkbox",
                    "ui:classNames": ["col-4 col-lg-2"]
                  },
                  liabilityInsurance: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/liabilityInsurance",
                    "ui:type": "checkbox",
                    "ui:classNames": ["col-4 col-lg-2"]
                  },
                  accidentInsurance: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/accidentInsurance",
                    "ui:type": "checkbox",
                    "ui:classNames": ["col-4 col-lg-2"]
                   },
                  a1Insurance: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/a1Insurance",
                    "ui:type": "checkbox",
                    "ui:classNames": ["col-4 col-lg-2"]
                  },
                  healthInsurance: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/healthInsurance",
                    "ui:type": "checkbox",
                    "ui:classNames": ["col-4 col-lg-2"]
                  },
                  liabilityInsuranceComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/liabilityInsuranceComment",
                    "ui:widget": "b-form-textarea",
                    type: "string",
                    "ui:classNames": ["col-lg-6 col-xl-3"]
                  },
                  accidentInsuranceComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/accidentInsuranceComment",
                    "ui:widget": "b-form-textarea",
                    type: "string",
                    "ui:classNames": ["col-lg-6 col-xl-3"]
                  },
                  additionalInsurances: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/additionalInsurances",
                    "ui:widget": "b-form-textarea",
                    type: "string",
                    "ui:classNames": ["col-lg-6 col-xl-3"]
                  },
                  healthInsuranceName: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/healthInsuranceName",
                    type: "string",
                    "ui:classNames": ["col-lg-6 col-xl-3"]
                  },
                  monthlyBillDays: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/monthlyBillDays",
                    "ui:classNames": ["col-md-6"]
                  },
                  monthlyBillDaysComment: {
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/monthlyBillDaysComment",
                    "ui:widget": "b-form-textarea",
                    type: "string",
                    "ui:classNames": ["col-md-6"],
                  },
                  offeredCourses: {
                    "ui:type": "collection",
                    "ui:label": "Angebotene Weiterbildungskurse",
                    "ui:description": "Welche Qualifizierungs und Weiterbildungskurse sind vorhanden",
                    type: "array",
                    $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/offeredCourses",
                    items: {
                      type: "string",
                      $ref: "https://crm.pflegehilfe-senioren.de/agency-model.json#/properties/offeredCourses/items",
                    }
                  },
                }
              },
            }
          }
        }
      }

      const form = reactive({...props.agency.attributes})

      const updatedForm = (formValue) => {
        if (formValue) {
          Object.assign(form, formValue)
        }
      }
      const formOptions = ref()

      watch(() => root.$store.state.agencies.profile, (newVal) => {
        formKey.value = Math.random()
          updatedForm(newVal?.attributes)
      }, { immediate: true })

      const sendForm = async () => {
        const data = { attributes: form }
        if (props.agency) {
          await root.$store.dispatch('agencies/edit', { id: props.agency.id, body: data })
          emit('updated')
        } else {
          await root.$store.dispatch('agencies/create', data)
        }
        root.$store.dispatch('agencies/get_profile')
      }
      return {
        formKey,
        form,
        formSchema,
        uiSchema,
        formOptions,
        sendForm,
        updatedForm
      }
    }
  })
